import { ICompany } from '@/shared/model/company.model';
import { IClientRate } from '@/shared/model/client-rate.model';

export interface IClient {
  id?: string;
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
  company?: ICompany | null;
  clientRate?: IClientRate;
  mycompanies?: ICompany[] | null;
}

export class Client implements IClient {
  constructor(
    public id?: string,
    public name?: string,
    public surname?: string,
    public email?: string,
    public phone?: string,
    public company?: ICompany | null,
    public clientRate?: IClientRate,
    public mycompanies?: ICompany[] | null
  ) {}
}

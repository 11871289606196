var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "mx-0 mb-8 pl-0", attrs: { id: "request-list" } },
    [
      _c("v-data-table", {
        staticClass: "remove-box-shadow custom-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.teamRequestDisplayed,
          "no-data-text": _vm.noDataMsg(),
          "hide-default-footer": "",
          "disable-pagination": "",
          "disable-sort": "",
        },
        scopedSlots: _vm._u([
          {
            key: "body.prepend",
            fn: function () {
              return [_c("div", { staticStyle: { height: "10px" } })]
            },
            proxy: true,
          },
          {
            key: "item.stemdoers",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  { staticClass: "chip-container" },
                  [
                    _vm._l(item.stemdoerRates, function (stemdoerRate, index) {
                      return [
                        index < 3
                          ? _c(
                              "v-chip",
                              {
                                key: "chip-rate-" + index,
                                staticClass: "overlapping-chip no-padding",
                                attrs: { color: "transparent" },
                              },
                              [
                                _c("SAvatarStemdoer", {
                                  staticClass: "avatar",
                                  attrs: {
                                    stemdoId: stemdoerRate.stemdoer.stemdoId,
                                  },
                                }),
                              ],
                              1
                            )
                          : index === 3
                          ? _c(
                              "v-chip",
                              {
                                key: "chip-rate-" + index,
                                staticClass: "overlapping-chip no-padding",
                                staticStyle: {
                                  border: "1px solid white !important",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            +" +
                                    _vm._s(item.stemdoerRates.length - 3) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                    _vm._v(" "),
                    _vm._l(item.stemdoers, function (stemdoer, index) {
                      return [
                        index < 3
                          ? _c(
                              "v-chip",
                              {
                                key: "chip-" + index,
                                staticClass: "overlapping-chip no-padding",
                                attrs: { color: "transparent" },
                              },
                              [
                                _c("SAvatarStemdoer", {
                                  staticClass: "avatar",
                                  attrs: { stemdoId: stemdoer.stemdoId },
                                }),
                              ],
                              1
                            )
                          : index === 3
                          ? _c(
                              "v-chip",
                              {
                                key: "chip-" + index,
                                staticClass: "overlapping-chip no-padding",
                                staticStyle: {
                                  border: "1px solid white !important",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            +" +
                                    _vm._s(item.stemdoers.length - 3) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "item.name",
            fn: function ({ item }) {
              return [
                _c("span", { staticClass: "name-cell" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]
            },
          },
          {
            key: "item.createdAt",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$d(Date.parse(item.createdAt), "twoDigit"))
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.hourlyRate",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(
                    "≈" +
                      _vm._s(_vm.$n(item.hourlyRate * 8 * 20, "decimal")) +
                      "€"
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.status",
            fn: function ({ item }) {
              return [
                _c("div", { staticClass: "status-container" }, [
                  _c("span", {
                    staticClass: "text-body-3 p-1 pl-3 pr-3",
                    style: {
                      backgroundColor: _vm.getStatusDetails(item.status)
                        .background,
                      border: `1px solid ${
                        _vm.getStatusDetails(item.status).border
                      }`,
                    },
                    domProps: {
                      textContent: _vm._s(
                        _vm.getStatusDetails(item.status).translation
                      ),
                    },
                  }),
                ]),
              ]
            },
          },
          {
            key: "item.action",
            fn: function ({ item }) {
              return [
                _c(
                  "v-menu",
                  {
                    staticClass: "justify-end menu-right",
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    { attrs: { icon: "" } },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticStyle: {
                                        "min-width": "24px",
                                        height: "24px",
                                        color: "var(--v-btnTertiary-base)",
                                      },
                                    },
                                    [_vm._v("mdi-dots-vertical")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "v-list",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "v-list-item",
                          {
                            staticStyle: {
                              "border-bottom": "1px solid var(--v-border-base)",
                            },
                          },
                          [
                            _c("v-list-item-title", {
                              staticClass: "list-option text-body-1",
                              domProps: {
                                textContent: _vm._s(
                                  item.status === _vm.RequestStatus.SAVED
                                    ? _vm.$t("requests.action.options")
                                    : _vm.$t("requests.action.details")
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleClickDetail(item)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        item.status != _vm.RequestStatus.ON_GOING &&
                        item.status != _vm.RequestStatus.CANCELLED_BY_MANAGER
                          ? _c(
                              "v-list-item",
                              [
                                _c("v-list-item-title", {
                                  staticClass: "list-option text-body-1",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("requests.action.delete")
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openCancelModal(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from '@/plugins/router';
import { useStore } from '@/plugins/vuex';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';

export default defineComponent({
  components: {
    SAvatarStemdoer,
  },
  props: {
    cartTotalItems: {
      type: Number,
      required: true,
    },
    isClientSelected: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const lastStemdoer = ref();
    const isAlertVisible = ref(false);
    const listOfAlerts = ref<string[]>([]);
    const isManager = store.getters.account?.authorities.includes('ROLE_GESTOR') ?? false;
    let timeOutId = null;
    const timeOut = ref(3000);

    const goToCheckout = () => {
      if (router.currentRoute.query['tr'] && isManager && props.isClientSelected) {
        router.push({
          name: 'teamRequestByIdManager',
          params: { id: router.currentRoute.query['tr'].toString() },
          query: { addedStemdoers: 'true' },
        });
        return;
      }
      if (isManager && props.isClientSelected) {
        router.push({ name: 'checkoutManager' });
      } else {
        router.push({ name: 'checkoutClient' });
      }
    };

    watch(
      () => {
        return props.cartTotalItems;
      },
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          lastStemdoer.value = store.getters['cartStore/cartItems'][newVal - 1];
          isAlertVisible.value = true;
          listOfAlerts.value.push(lastStemdoer.value);
          timeOut.value = 3000;
          if (timeOutId) {
            clearTimeout(timeOutId);
          }
          timeOutId = setTimeout(() => {
            isAlertVisible.value = false;
          }, timeOut.value);
        }
      }
    );

    return { goToCheckout, lastStemdoer, isAlertVisible };
  },
});

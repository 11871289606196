var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "RequestDetail" } },
    [
      _c(
        "div",
        { staticClass: "header px-7 pt-6 pb-5" },
        [
          _c("SButtonBack"),
          _vm._v(" "),
          _c("div", { staticClass: "center-div" }, [
            _c("span", {
              staticClass: "font-weight-bold header-text",
              domProps: {
                textContent: _vm._s(_vm.$t("managerTeamDetail.title-page")),
              },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.teamRequest
        ? _c(
            "v-row",
            { staticClass: "pa-0 ma-0 mt-11" },
            [
              _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "7" } }, [
                _c("h3", {
                  staticClass: "ml-8 team-info mb-6",
                  domProps: {
                    textContent: _vm._s(_vm.$t("managerTeamDetail.team")),
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex flex-column px-8" },
                  _vm._l(
                    _vm.teamRequest.stemdoerRates,
                    function (stemdoerRate) {
                      return _c(
                        "div",
                        { key: stemdoerRate.stemdoer.id },
                        [
                          _c("SCardStemdoerEditableRate", {
                            staticClass: "mb-4",
                            attrs: {
                              stemdoer: stemdoerRate.stemdoer,
                              rate: stemdoerRate.rate,
                              updateRate: _vm.updateStemdoerRate,
                            },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0 ma-0", attrs: { cols: "5" } },
                [
                  _c(
                    "h3",
                    {
                      staticClass: "team-info mb-6",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("managerTeamDetail.team-details")
                        ),
                      },
                    },
                    [_vm._v("Detalles equipo")]
                  ),
                  _vm._v(" "),
                  _vm.teamRequest != null
                    ? _c("TeamDetail", {
                        attrs: {
                          teamRequest: _vm.teamRequest,
                          isTRModified:
                            _vm.isAnyRateChanged || _vm.areStemdoersChanged,
                          refreshTeamRequest: _vm.refreshTeamRequest,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          isOpen: _vm.isAvailableStemdoersModalOpen,
          title: _vm.$t("managerTeamDetail.modalStemoderNoAvailable.title"),
          maxWidth: "500px",
          closeModal: _vm.closeAvailableStemdoersModal,
          customProps: { bodyPaddingY: 0, fullWidthFooter: true },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "span",
                  { staticClass: "text-body-1 text--text base--base" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "managerTeamDetail.modalStemoderNoAvailable.message"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "v-container",
                  { staticClass: "p-0" },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pr-2", attrs: { cols: "6" } },
                          [
                            _c("sButton", {
                              attrs: {
                                btnStyle: "secondary",
                                sText:
                                  "managerTeamDetail.modalStemoderNoAvailable.buttonContinue",
                                action: _vm.handleContinueAction,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "pl-2", attrs: { cols: "6" } },
                          [
                            _c("sButton", {
                              attrs: {
                                btnStyle: "primary",
                                sText:
                                  "managerTeamDetail.modalStemoderNoAvailable.buttonExplore",
                                action: _vm.gotoExplore,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
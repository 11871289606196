import { defineComponent, inject, ref, computed } from 'vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { useRouter } from '@/plugins/router';
import ResourceService from '@/shared/ResourceService.service';
import { ITeamRequest } from '@/shared/model/team-request.model';
import { useI18N } from '@/plugins/i18n';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object,
      required: true,
    },
    closeRequestDetailModal: {
      type: Function,
    },
    refreshTeamRequestData: {
      type: Function,
    },
  },
  components: {
    SModalCentered,
  },
  setup(props) {
    const router = useRouter();
    const i18n = useI18N();
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const resourceService: ResourceService = inject('resourceService');
    const isBlockModalOpen = ref(false);
    const status = computed(() => props.teamRequest.status);
    const modifiedByManager = computed(() => props.teamRequest.modifiedByManager);
    const isAcceptModalOpen = ref(false);
    const isAcceptButtonDisabled = ref(false);
    const isBlockTeamDisabled = ref(false);

    const openSuccesfullyBlockedTeamModal = () => {
      isBlockModalOpen.value = true;
    };

    const closeSuccesfullyBlockedTeamModal = () => {
      isBlockModalOpen.value = false;
    };

    const blockTeamRequest = (teamRequestId: string) => {
      isBlockTeamDisabled.value = true;
      teamRequestService
        .changeStatus(teamRequestId, RequestStatus.PENDING)
        .then(teamRequest => {
          sendEmailWithTRDetails(teamRequest);
          props.refreshTeamRequestData();
          props.closeRequestDetailModal();
          openSuccesfullyBlockedTeamModal();
          isBlockTeamDisabled.value = false;
        })
        .catch(error => {
          console.error(error);
          isBlockTeamDisabled.value = false;
        });
    };

    const sendEmailWithTRDetails = (teamRequest: ITeamRequest) => {
      const stemdoerIds = teamRequest.stemdoerRates.map(stemdoerRate => stemdoerRate.stemdoer.id);
      const stemdoers = teamRequest.stemdoerRates.map(stemdoerRate => stemdoerRate.stemdoer);
      const client = teamRequest.client;
      const clientEmail = client.email;
      const json = {
        isTeam: true,
        stemdoerShowBadge: {},
        ratesList: {},
        initDate: teamRequest.initDate,
        endDate: teamRequest.endDate,
        locale: i18n.locale,
        paramId: teamRequest.id,
        idClient: client.id,
        nameClient: client
          ? client.name.charAt(0).toLocaleUpperCase() +
            client.name.slice(1) +
            ' ' +
            client.surname.substring(0, 1).toLocaleUpperCase() +
            '.'
          : '',
        emailClient: clientEmail,
        nameStemdoers: [],
      };
      for (let i = 0; i < stemdoers.length; i++) {
        const stemdoer = stemdoers[i];
        const formattedName =
          stemdoer.name.charAt(0).toLocaleUpperCase() + stemdoer.name.slice(1) + ' ' + stemdoer.surname.charAt(0).toLocaleUpperCase() + '.';
        json.nameStemdoers.push(formattedName);
      }
      resourceService.sendEmailWithTRDetails(stemdoerIds, JSON.stringify(json), clientEmail);
    };

    const redirectBtnSavedtoEdit = id => {
      router.push({ name: 'teamRequestByIdClient', params: { id } });
    };

    const openAcceptModal = () => {
      isAcceptModalOpen.value = true;
    };

    const acceptCloseModal = () => {
      isAcceptModalOpen.value = false;
    };

    const acceptGestorProposal = () => {
      isAcceptButtonDisabled.value = true;
      teamRequestService
        .changeStatus(props.teamRequest.id, RequestStatus.ON_GOING)
        .then(() => {
          openAcceptModal();
          props.closeRequestDetailModal();
          props.refreshTeamRequestData();
          isAcceptButtonDisabled.value = false;
        })
        .catch(error => {
          console.error(error);
          isAcceptButtonDisabled.value = false;
        });
    };

    const sendMessage = () => {
      console.log('Send Message');
    };

    return {
      status,
      modifiedByManager,
      RequestStatus,
      isAcceptModalOpen,
      isAcceptButtonDisabled,
      isBlockModalOpen,
      isBlockTeamDisabled,
      acceptGestorProposal,
      sendMessage,
      redirectBtnSavedtoEdit,
      openSuccesfullyBlockedTeamModal,
      closeSuccesfullyBlockedTeamModal,
      blockTeamRequest,
      acceptCloseModal,
    };
  },
});

import { IClient } from '@/shared/model/client.model';
import { IVertical } from '@/shared/model/vertical.model';
import { ICertification } from '@/shared/model/certification.model';

import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { ITeamActivity } from './team-activity.model';
import { StemdoerRate, IStemdoerRate } from './stemdoer-rate.model';
import { IStemdoer } from './stemdoer.model';
import { IMessage } from '@/shared/model/message.model';
import moment from 'moment';

export interface ITeamRequest {
  id?: string;
  name?: string | null;
  createdAt?: Date;
  initDate?: Date;
  endDate?: Date;
  english?: boolean | null;
  paramsUrl?: string | null;
  techPreferences?: string | null;
  jobDescription?: string | null;
  hourlyRate?: number | null;
  status?: RequestStatus | null;
  client?: IClient;
  clientId?: string;
  vertical?: IVertical | null;
  certifications?: ICertification[] | null;
  stemdoers?: IStemdoer[] | null;
  stemdoerRates?: IStemdoerRate[] | null;
  teamActivities?: ITeamActivity[] | null;
  validation?: boolean | null;
  messages?: IMessage[] | null;
  canBeValidated?: boolean;
  modifiedByManager?: boolean;
}

export class TeamRequest implements ITeamRequest {
  id?: string;
  name?: string;
  createdAt?: Date;
  initDate?: Date;
  endDate?: Date;
  english?: boolean;
  paramsUrl?: string;
  techPreferences?: string;
  jobDescription?: string;
  hourlyRate?: number;
  status?: RequestStatus;
  client?: IClient;
  clientId?: string;
  vertical?: IVertical;
  certifications?: ICertification[];
  stemdoers?: IStemdoer[];
  stemdoerRates?: IStemdoerRate[];
  teamActivities?: ITeamActivity[];
  validation?: boolean;
  messages?: IMessage[];
  canBeValidated?: boolean;
  modifiedByManager?: boolean;

  constructor(data: ITeamRequest) {
    this.id = data?.id;
    this.name = data.name;
    this.createdAt = data.createdAt;
    this.initDate = data.initDate;
    this.endDate = data.endDate;
    this.english = data.english;
    this.paramsUrl = data.paramsUrl;
    this.techPreferences = data.techPreferences;
    this.jobDescription = data.jobDescription;
    this.hourlyRate = data.hourlyRate;
    this.status = data.status;
    this.client = data.client;
    this.clientId = data.clientId;
    this.vertical = data.vertical;
    this.certifications = data.certifications;
    this.stemdoers = data.stemdoers;
    this.stemdoerRates = data.stemdoerRates;
    this.teamActivities = data.teamActivities;
    this.validation = data.validation;
    this.messages = data.messages;
    this.canBeValidated = data.canBeValidated;
    this.modifiedByManager = data.modifiedByManager;
  }

  static empty = () => {
    return new TeamRequest({
      name: null,
      createdAt: null,
      initDate: null,
      endDate: null,
      english: null,
      paramsUrl: null,
      techPreferences: null,
      jobDescription: null,
      hourlyRate: null,
      status: null,
      client: null,
      clientId: null,
      vertical: null,
      certifications: null,
      stemdoers: null,
      stemdoerRates: null,
      teamActivities: null,
      validation: null,
      messages: null,
      canBeValidated: null,
      modifiedByManager: null,
    });
  };

  removeNotAvailablesOrBlockedStemdoers = () => {
    this.stemdoerRates = this.stemdoerRates.filter(stemdoerRate => {
      return (
        stemdoerRate.stemdoer.availability.available === true &&
        (!stemdoerRate.stemdoer.blockedBy ||
          stemdoerRate.stemdoer.blockedBy?.teamRequestId === this.id ||
          stemdoerRate.stemdoer.blockedBy === null ||
          moment(stemdoerRate.stemdoer.blockedBy?.blockedUntil).isBefore(moment()))
      );
    });
    this.canBeValidated = true;
  };

  addStemdoers = (stemdoerRates: IStemdoerRate[]) => {
    stemdoerRates.forEach(stemdoerRate => {
      if (!this.stemdoerRates.some(sr => sr.stemdoer.id === stemdoerRate.stemdoer.id)) {
        this.stemdoerRates.push(stemdoerRate);
      }
    });
  };
}

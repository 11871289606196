import { ICohort } from '@/shared/model/cohort.model';
import { IVertical } from '@/shared/model/vertical.model';
import { ITeamRequest } from '@/shared/model/team-request.model';
import { IStemdoerTeam } from './stemdoer-team.model';
import { IRate } from './rate.model';
import { IExperience } from './experience.model';
import { ExperienceCollection } from './experience-collection.model';
import { ITechnologyScore } from './technology-score.model';
import { IAvailability } from './availability.model';
import { IBlockedByTR } from './blocked-by.model';
import { IStemdoerCertification } from './stemdoerCertification.model';

export interface IStemdoer {
  id?: string;
  stemdoId?: string;
  name?: string;
  surname?: string;
  position?: string;
  seniority?: string;
  rate?: number;
  email?: string;
  hireDate?: Date;
  mbti?: string | null;
  english?: string | null;
  education?: string | null;
  softSkills?: number[] | null;
  experience?: IExperience[];
  cohorts?: ICohort[] | null;
  verticals?: IVertical[] | null;
  teamRequests?: ITeamRequest[] | null;
  stemdoerTeams?: IStemdoerTeam[] | null;
  technologyScores?: ITechnologyScore[] | null;
  certifications?: IStemdoerCertification[] | null;
  hide?: boolean | null;
  status?: string;
  availability?: IAvailability | null;
  blockedBy?: IBlockedByTR | null;
}

export class Stemdoer implements IStemdoer {
  constructor(
    public id?: string,
    public stemdoId?: string,
    public name?: string,
    public surname?: string,
    public position?: string,
    public seniority?: string,
    public rate?: number,
    public email?: string,
    public hireDate?: Date,
    public mbti?: string | null,
    public english?: string | null,
    public education?: string | null,
    public softSkills?: number[] | null,
    public experience?: ExperienceCollection | null,
    public cohorts?: ICohort[] | null,
    public verticals?: IVertical[] | null,
    public teamRequests?: ITeamRequest[] | null,
    public stemdoerTeams?: IStemdoerTeam[] | null,
    public technologyScores?: ITechnologyScore[] | null,
    public certifications?: IStemdoerCertification[] | null,
    public hide?: boolean | null,
    public status?: string,
    public availability?: IAvailability | null,
    public blockedBy?: IBlockedByTR | null
  ) {}

  public static calculateRate = (clientRates: IRate[], seniority: string) => {
    const rate = clientRates.find(rate => rate.seniority.localeCompare(seniority, undefined, { sensitivity: 'base' }) === 0)?.value;
    return rate ? rate : 0;
  };
}

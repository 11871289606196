var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "login" } }, [
    _c("div", { staticClass: "login-container" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "login-form" }, [
        _c("div", { staticClass: "form-text" }, [
          _c("div", [
            _c("span", {
              staticClass: "text-body-1",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.messages.info.register.noaccount")
                ),
              },
            }),
            _vm._v(" "),
            _c("a", {
              staticClass: "alert-link text-body-1-bold",
              staticStyle: { color: "#4237ff" },
              attrs: { "data-cy": "registerOpen" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.messages.info.register.link")
                ),
              },
              on: { click: _vm.gotoRegister },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-stemdo", attrs: { role: "form" } },
          [
            _c("div", { staticClass: "form-title" }, [
              _c("h1", {
                domProps: { textContent: _vm._s(_vm.$t("login.title")) },
              }),
              _vm._v(" "),
              _vm.requestPassword
                ? _c("div", { staticClass: "login-request" }, [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("login.password.request")),
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.registerAccount
                ? _c("div", { staticClass: "login-request" }, [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("login.messages.register")),
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.errorMessage
                ? _c("div", { staticClass: "login-error" }, [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("login.messages.error.message")
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("label", {
                staticClass: "login-span",
                attrs: { for: "input-form-stemdo" },
                domProps: {
                  textContent: _vm._s(_vm.$t("login.form['username']")),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "input-form-group-stemdo",
                  attrs: { id: "inpunts" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.$v.username.$model,
                        expression: "$v.username.$model",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "input-form-stemdo is-invalid",
                    class: {
                      "input-error":
                        !_vm.$v.username.email ||
                        (_vm.isFormInvalid && !_vm.$v.username.email.$model),
                    },
                    attrs: {
                      id: "input-form-stemdo",
                      type: "email",
                      name: "login",
                      "data-cy": "usernameLogin",
                      "placeholder-for": "username",
                      autofocus: "",
                    },
                    domProps: { value: _vm.$v.username.$model },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$v.username,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  !_vm.$v.username.email ||
                  (_vm.$v.username.email.model && !_vm.isFormInvalid)
                    ? _c("span", {
                        staticClass: "span-error",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("global.messages.validate.email.required")
                          ),
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { "margin-top": "22px" } }, [
              _c("label", {
                staticClass: "login-span",
                attrs: { for: "password" },
                domProps: {
                  textContent: _vm._s(_vm.$t("login.form['password']")),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "input-form-group-stemdo",
                  attrs: { id: "inpunts" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.$v.password.$model,
                        expression: "$v.password.$model",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "input-form-stemdo",
                    class: {
                      "input-error":
                        !_vm.$v.password ||
                        (_vm.isFormInvalid && !_vm.$v.password.$model),
                    },
                    attrs: {
                      id: "password",
                      type: "password",
                      name: "password",
                      state: !_vm.$v.password.$required,
                      "data-cy": "passwordLogin",
                    },
                    domProps: { value: _vm.$v.password.$model },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$v.password,
                          "$model",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "login-remember" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rememberMe,
                    expression: "rememberMe",
                  },
                ],
                staticStyle: { "padding-right": "5px" },
                attrs: {
                  type: "checkbox",
                  id: "rememberMe",
                  name: "rememberMe",
                  checked: "",
                },
                domProps: {
                  checked: Array.isArray(_vm.rememberMe)
                    ? _vm._i(_vm.rememberMe, null) > -1
                    : _vm.rememberMe,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.rememberMe,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.rememberMe = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.rememberMe = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.rememberMe = $$c
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("label", {
                attrs: { for: "rememberMe" },
                domProps: {
                  textContent: _vm._s(_vm.$t("login.form.rememberme")),
                },
              }),
              _vm._v(" "),
              _c("a", {
                staticClass: "alert-link text-body-1-bold pl-2",
                attrs: { "data-cy": "forgetYourPasswordSelector" },
                domProps: {
                  textContent: _vm._s(_vm.$t("login.password.forgot")),
                },
                on: { click: _vm.gotoResetPassword },
              }),
            ]),
            _vm._v(" "),
            _c("SButton", {
              attrs: {
                btnStyle: "primary",
                sText: "login.form.button",
                action: _vm.validateForm,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "login-image" }, [
      _c("img", {
        attrs: {
          src: require("@content/images/stemdo-black-logo.png"),
          alt: "stemdo Logo",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import SButtonBack from '@/components/stemdo-components/s-button-back/SButtonBack.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import TeamDetail from './team-detail/TeamDetail.vue';
import { useRouter } from '@/plugins/router';
import { TeamRequest } from '@/shared/model/team-request.model';
import { IStemdoerRate } from '@/shared/model/stemdoer-rate.model';
import store from '@/plugins/vuex';

export default defineComponent({
  components: {
    SButtonBack,
    TeamDetail,
  },
  setup() {
    const router = useRouter();
    const isAvailableStemdoersModalOpen = ref(false);
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const teamRequest = ref<TeamRequest>(null);
    const originalStemdoerRates = ref<IStemdoerRate[]>([]);
    const clientSelected = computed(() => store.getters['cartStore/clientSelected']);
    const rate = ref(0);
    const isAnyRateChanged = computed(() => {
      return teamRequest.value.stemdoerRates.some(sr => {
        const originalRate = originalStemdoerRates.value.find(osr => osr.stemdoer.id === sr.stemdoer.id).rate;
        return sr.rate !== originalRate;
      });
    });
    const areStemdoersChanged = ref(false);

    onMounted(() => {
      const id = router.currentRoute.params['id'].toString();
      retrieveTeamRequest(id);
    });

    const loadTeamRequestInfo = (isRefresh?: boolean) => {
      if (isRefresh !== true) {
        const stemdoerRatesReadyToBeAdded = store.getters['cartStore/cartItems'].map(stemdoer => {
          return { rate: 36, stemdoer: stemdoer };
        });
        addRateToStemdoers(teamRequest.value.stemdoerRates);
        teamRequest.value.stemdoerRates = originalStemdoerRates.value;
        teamRequest.value.addStemdoers(stemdoerRatesReadyToBeAdded);
        newStemdoerOriginalRates();
        teamRequest.value.removeNotAvailablesOrBlockedStemdoers();
        areStemdoersChanged.value = true;
      }
      if (isRefresh === true) areStemdoersChanged.value = false;
    };

    const newStemdoerOriginalRates = () => {
      originalStemdoerRates.value = teamRequest.value.stemdoerRates.map(sr => Object.assign({}, sr));
    };

    const addRateToStemdoers = (stemdoerRate: IStemdoerRate[]) => {
      let ratesSource: any[] = [];
      stemdoerRate.forEach(rateObj => {
        const existingRate = originalStemdoerRates.value.find(item => item.stemdoer === rateObj.stemdoer);
        if (existingRate) {
          existingRate.rate = rateObj.rate;
        }

        if (clientSelected.value) {
          ratesSource = clientSelected.value.clientRate?.rates || [];
        }

        if (Array.isArray(ratesSource)) {
          rateObj.rate = 36;
          ratesSource.forEach(item => {
            const seniority = item.seniority.toLowerCase().replace(/[-_]/g, '');
            const stemdoerSeniority = rateObj.stemdoer.seniority.toLowerCase().replace(/[-_]/g, '');
            if (seniority === stemdoerSeniority) {
              rateObj.rate = rate.value;
            }
          });
        }
      });
    };

    const retrieveTeamRequest = (id: string, isRefresh?: boolean) => {
      teamRequestService
        .find(id)
        .then(tr => {
          originalStemdoerRates.value = tr.stemdoerRates?.map(sr => Object.assign({}, sr));
          teamRequest.value = new TeamRequest(tr);
          if (router.currentRoute.query['addedStemdoers'] == 'true') loadTeamRequestInfo(isRefresh);
          if (!router.currentRoute.query['addedStemdoers'] && !teamRequest.value.canBeValidated) openAvailableStemdoersModal();
        })
        .catch(error => {
          console.error(error);
        });
    };

    const updateStemdoerRate = (stemdoerId: string, newRate: number) => {
      teamRequest.value.stemdoerRates.find(sr => sr.stemdoer.id === stemdoerId).rate = newRate;
    };

    const refreshTeamRequest = () => {
      areStemdoersChanged.value = false;
      retrieveTeamRequest(teamRequest.value.id, true);
    };

    const closeAvailableStemdoersModal = () => {
      isAvailableStemdoersModalOpen.value = false;
    };

    const openAvailableStemdoersModal = () => {
      isAvailableStemdoersModalOpen.value = true;
    };

    const handleContinueAction = () => {
      teamRequest.value.removeNotAvailablesOrBlockedStemdoers();
      areStemdoersChanged.value = true;
      closeAvailableStemdoersModal();
    };

    const gotoExplore = () => {
      router.push({
        name: 'exploreManager',
        query: { tr: teamRequest.value.id },
      });
    };

    return {
      teamRequest,
      isAnyRateChanged,
      areStemdoersChanged,
      isAvailableStemdoersModalOpen,
      updateStemdoerRate,
      refreshTeamRequest,
      closeAvailableStemdoersModal,
      handleContinueAction,
      gotoExplore,
    };
  },
});

import { defineComponent, inject, onMounted, ref, computed, watch } from 'vue';
import { useStore } from '@/plugins/vuex';
import StemdoerService from '@/sections/admin/entities/stemdoer/stemdoer.service';
import ClientRateService from '@/sections/admin/entities/client-rate/client-rate.service';
import { IStemdoer, Stemdoer } from '@/shared/model/stemdoer.model';
import { IRate, Rate } from '@/shared/model/rate.model';
import StemdoerList from './stemdoer-list/StemdoerList.vue';
import TopFilter from './top-filter/TopFilter.vue';
import BtnMyTeam from './btn-my-team/BtnMyTeam.vue';
import NoResultInfo from './no-result-info/NoResultInfo.vue';
import Filters, { FilterFields } from './model/Filters';
import SwitchAvailability from './switch-availability/SwitchAvailability.vue';
import { filterStemdoers, filterStemdoersClosestMatch } from './model/StemdoerFilter';
import StemdoerListSkeleton from './stemdoer-list-skeleton/StemdoerListSkeleton.vue';
import ClientSelector from './client-selector/ClientSelector.vue';
import { IAtocompleteProps } from './model/Filter';
import EmptyStemdoerList from './empty-stemdoer-list/EmptyStemdoerList.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import router from '@/router';
import { ITeamRequest } from '@/shared/model/team-request.model';

export default defineComponent({
  components: {
    TopFilter,
    StemdoerList,
    SwitchAvailability,
    BtnMyTeam,
    NoResultInfo,
    StemdoerListSkeleton,
    ClientSelector,
    EmptyStemdoerList,
  },
  setup() {
    const store = useStore();
    const stemdoerService: StemdoerService = inject('stemdoerService');
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const clientRateService: ClientRateService = inject('clientRateService');

    const stemdoers = ref<IStemdoer[]>([]);
    const stemdoersNotInCart = ref<IStemdoer[]>([]);
    const visibleStemdoers = ref<IStemdoer[]>([]);
    const isStemdoersListEmpty = ref(false);
    const rates = ref<IRate[]>([]);
    const isLoading = ref(true);
    const isFiltered = ref(false);
    const isFilteredByClosestMatch = ref(false);
    const isManager = computed(() => store.getters.account?.authorities.includes('ROLE_GESTOR') ?? false);
    const clientSelected = computed(() => {
      return store.getters['cartStore/clientSelected'];
    });

    const cartTotalItems = computed(() => {
      return store.getters['cartStore/cartTotalItems'];
    });

    const isClientSelected = computed(() => {
      if (isManager.value) {
        return clientSelected.value != null && Object.keys(clientSelected.value).length > 0;
      }
      return true;
    });

    const displaySkeleton = computed(() => {
      return isLoading.value || (stemdoers.value.length === 0 && isLoading.value);
    });

    onMounted(async () => {
      if (!isManager.value) {
        await getClientRates();
      }
      retrieveAllStemdoers();
      if (router.currentRoute.query['tr']) loadTeamRequestInfo();
    });

    watch(
      () => store.getters['filterStore/filters'],
      (filters: Filters, oldFilters: Filters) => {
        if (hasTechFilterChanged(filters, oldFilters)) {
          retrieveAllStemdoers();
        } else {
          visibleStemdoers.value = filter(filters, stemdoersNotInCart.value);
          checkIfVisibleStemdoersIsEmpty();
        }
      },
      { deep: true }
    );

    watch(clientSelected, () => {});

    const hasTechFilterChanged = (newFiltersState: Filters, oldFiltersState: Filters) => {
      const oldTechFilter = oldFiltersState.getFiltersByField(FilterFields.Technology)[0];
      const newTechFilter = newFiltersState.getFiltersByField(FilterFields.Technology)[0];
      const oldTechFilterProps = oldTechFilter?.props as IAtocompleteProps;
      const newTechFilterProps = newTechFilter?.props as IAtocompleteProps;
      return !oldTechFilter || !newTechFilter || oldTechFilterProps.value.id !== newTechFilterProps.value.id;
    };

    const checkIfVisibleStemdoersIsEmpty = () => {
      isStemdoersListEmpty.value = false;
      if (visibleStemdoers.value.length == 0) {
        isStemdoersListEmpty.value = true;
      } else {
        isStemdoersListEmpty.value = false;
      }
    };

    /*
      TODO: 
        y qué pasa si la ID de la url no trae ninguna TR? 
        gestiona esa casuistica y lo hacemos facil, que no cargue nada al carrito ni al selector
        que entre al explorar normal
    */
    const loadTeamRequestInfo = () => {
      store.commit('cartStore/clearCart');
      const teamRequestId: string = router.currentRoute.query['tr'].toString();
      teamRequestService.find(teamRequestId).then(tr => {
        store.commit('cartStore/setClient', tr.client);
        tr.stemdoerRates.forEach(sr => {
          if (sr.stemdoer.availability.available == true) {
            store.commit('cartStore/addStemdoer', sr.stemdoer);
          }
        });
      });
    };

    const getClientRates = async () => {
      const res = await clientRateService.retrieveClientRates();
      const data = res.data;
      data.forEach(item => {
        rates.value.push(Rate.toRate(item).toDTO());
      });
    };

    const retrieveAllStemdoers = () => {
      isFiltered.value = false;
      isLoading.value = true;

      const filters = store.getters['filterStore/filters'];
      const techFilter = filters.find(filter => filter.field == FilterFields.Technology);

      if (techFilter && techFilter.props && techFilter.props.value) {
        const verticalId = techFilter.props.value.id;

        stemdoerService
          .retrieveStemdoersByDate(verticalId, new Date())
          .then(res => {
            stemdoers.value = res.data;
            stemdoers.value.map(
              s => (s.rate = Stemdoer.calculateRate(rates.value, s.seniority === 'MID_SENIOR' ? 'MID-SENIOR' : s.seniority))
            );
            stemdoersNotInCart.value = getStemdoersNotInCart(stemdoers.value);
            visibleStemdoers.value = filter(filters, stemdoersNotInCart.value);
            checkIfVisibleStemdoersIsEmpty();
            isLoading.value = false;
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        checkIfVisibleStemdoersIsEmpty();
        isLoading.value = false;
      }
    };

    const getStemdoersNotInCart = (stemdoers: IStemdoer[]): IStemdoer[] => {
      let stemdoersNotInCart = [...stemdoers];
      if (cartTotalItems.value > 0) {
        const cartItemsIds = store.getters['cartStore/cartItems'].map(s => s.id);
        stemdoersNotInCart = stemdoersNotInCart.filter(s => !cartItemsIds.includes(s.id));
      }
      return stemdoersNotInCart;
    };

    const filter = (filters: Filters, stemdoers: IStemdoer[]): IStemdoer[] => {
      let filteredStemdoers: IStemdoer[] = [...stemdoers];
      filteredStemdoers = filterStemdoers(filters, filteredStemdoers);
      isFiltered.value = true;
      isFilteredByClosestMatch.value = false;
      if (filteredStemdoers.length === 0) {
        filteredStemdoers = filterByClosestMatch(filters, stemdoersNotInCart.value);
      }
      return filteredStemdoers;
    };

    const filterByClosestMatch = (filters: Filters, stemdoers: IStemdoer[]): IStemdoer[] => {
      let filteredStemdoers: IStemdoer[] = [...stemdoers];
      filteredStemdoers = filterStemdoersClosestMatch(filters, filteredStemdoers);
      isFiltered.value = true;
      isFilteredByClosestMatch.value = true;
      return filteredStemdoers;
    };

    const addStemdoerToCart = (stemdoer: IStemdoer) => {
      stemdoersNotInCart.value = visibleStemdoers.value.filter(s => s.id !== stemdoer.id);
      visibleStemdoers.value = visibleStemdoers.value.filter(s => s.id !== stemdoer.id);
      store.commit('cartStore/addStemdoer', stemdoer);
      document.documentElement.style.overflow = 'auto';
      checkIfVisibleStemdoersIsEmpty();
    };

    const changeAvailableFilter = (field: FilterFields, newVal: boolean) => {
      const payload = { field, newVal };
      store.commit('filterStore/updateSwitchReverseState', payload);
    };

    return {
      stemdoers,
      rates,
      visibleStemdoers,
      cartTotalItems,
      FilterFields,
      isFiltered,
      isClientSelected,
      clientSelected,
      isFilteredByClosestMatch,
      displaySkeleton,
      isLoading,
      isManager,
      isStemdoersListEmpty,
      addStemdoerToCart,
      changeAvailableFilter,
    };
  },
});

import { computed, ref, onMounted, watch } from 'vue';
import axios from 'axios';
import SelectorComponents from '@/components/stemdo-components/stemdo-components.component.vue';
import { useRouter } from '@/plugins/router';
import { required, email, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import localStorageFacade, { StorageKey } from '@/shared/local-store-facade/LocalStoreFacade';
import { useStore } from '@/plugins/vuex';
import { logout } from '@/shared/security/security';
import { useVuelidate } from '@vuelidate/core';

export default {
  mixins: [validationMixin],

  validations: {
    username: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(254),
      email,
    },
    password: {
      pattern: helpers.regex('alpha', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/),
      required,
      minLength: minLength(1),
      maxLength: maxLength(254),
    },
  },

  components: {
    SelectorComponents,
  },
  setup(_, { emit }) {
    const store = useStore();
    const router = useRouter();
    const authenticationError = ref(null);
    const errorMessage = ref(false);
    const requestPassword = ref(false);
    const registerAccount = ref(false);
    const v$ = useVuelidate();
    const isFormInvalid = ref<boolean | null>(null);
    const username = ref('');
    const password = ref('');
    const authorities = ref(null);
    const rememberMe = ref<boolean | null>(null);
    const isSubmitDisabled = computed(() => !username.value || !password.value);

    onMounted(() => {
      if (router.currentRoute.params.passwordResetSuccess) {
        requestPassword.value = true;
      }
      if (router.currentRoute.params.registerSuccess) {
        registerAccount.value = true;
      }
    });

    const emitSms = (payload: { error?: boolean; authenticationError?: boolean }) => {
      emit('sms', payload);
    };

    const bytesToBase64 = bytes => {
      const binString = String.fromCodePoint(...bytes);
      return btoa(binString);
    };

    watch([username, password], () => {
      isFormInvalid.value = null;
    });

    const gotoRegister = () => {
      router.push('/register');
    };

    const gotoResetPassword = () => {
      router.push('/account/reset/request');
    };

    const validateForm = () => {
      isFormInvalid.value = v$.value.username.$invalid || v$.value.password.$invalid;
      if (!isFormInvalid.value) {
        doLogin();
      }
    };

    const doLogin = (): void => {
      emitSms({ error: false });
      emitSms({ authenticationError: false });
      const data = {
        username: username.value,
        password: bytesToBase64(new TextEncoder().encode(password.value)),
        rememberMe: rememberMe.value,
        authorities: authorities.value,
      };
      axios
        .post('api/authenticate', data)
        .then(async result => {
          logout();
          sessionStorage.setItem('loginName', result.config.data.split('"')[3]);
          const bearerToken = result.headers.authorization;
          if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
            const jwt = bearerToken.slice(7, bearerToken.length);
            if (rememberMe.value) {
              localStorageFacade.set(StorageKey.AUTH_TOKEN, jwt);
            } else {
              localStorageFacade.set(StorageKey.AUTH_TOKEN, jwt);
            }
          }
          authenticationError.value = false;

          router.push('/').catch(() => {});
        })
        .catch(err => {
          switch (err.response.data.detail) {
            case 401:
              emit('sms', { authenticationError: true });
              errorMessage.value = true;

              break;
            default:
              emit('sms', { error: true });
              errorMessage.value = true;
          }
        });
    };
    return {
      username,
      password,
      isSubmitDisabled,
      isFormInvalid,
      errorMessage,
      rememberMe,
      requestPassword,
      registerAccount,
      validateForm,
      gotoRegister,
      gotoResetPassword,
    };
  },
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { id: "stemdoer-card" } },
    [
      _c(
        "v-container",
        { staticClass: "p-0 m-0 h-100 w-100", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "h-100 w-100", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column p-3 h-100",
                  attrs: { cols: "auto", id: "StemdoerCardfirstColImg" },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex justify-center flex-grow-0 px-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { position: "relative" },
                          attrs: { width: "90px" },
                        },
                        [
                          _vm.mutableStemdoer.english != "A1" &&
                          _vm.mutableStemdoer.english != "A2" &&
                          _vm.mutableStemdoer.english != ""
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    bottom: "1em",
                                    right: "1em",
                                    "z-index": "2",
                                    transform: "scale(1.16)",
                                  },
                                  attrs: { color: "#f9f9f9" },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "badge" }, slot: "badge" },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          margin: "-9px",
                                          "background-color": "#f9f9f9",
                                          "border-radius": "50%",
                                        },
                                        attrs: {
                                          src: "/content/svgs/english-uk-lang.svg",
                                          width: "22em",
                                          height: "22em",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("SAvatarStemdoer", {
                            staticClass: "my-3",
                            staticStyle: { "z-index": "1" },
                            attrs: { stemdoId: _vm.mutableStemdoer.stemdoId },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "flex-grow-0 mt-1",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center",
                          attrs: { cols: "12" },
                        },
                        [
                          !_vm.mutableStemdoer.availability.available &&
                          _vm.mutableStemdoer.availability.availableAt !== null
                            ? _c(
                                "div",
                                { staticClass: "text-center available-label" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "available-text p-1" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "explore.stemdoer-card.availability"
                                            )
                                          ) +
                                          "\n                " +
                                          _vm._s(
                                            _vm.$d(
                                              Date.parse(
                                                _vm.mutableStemdoer.availability
                                                  .availableAt
                                              ),
                                              "twoDigitWithoutYear"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : !_vm.mutableStemdoer.availability.available &&
                              _vm.mutableStemdoer.availability.availableAt ===
                                null
                            ? _c(
                                "div",
                                { staticClass: "text-center available-label" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "available-text p-1" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "explore.stemdoer-card.indeterminate"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "flex-grow-0 mt-auto",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _vm.isLoaded
                        ? _c(
                            "v-col",
                            { staticClass: "d-flex flex-row pl-1" },
                            _vm._l(
                              _vm.getCertification(),
                              function (cert, ind) {
                                return _vm.Certification.getCertificationImgUrlByName(
                                  cert.name
                                ) != "" && ind < 3
                                  ? _c(
                                      "div",
                                      {
                                        key: cert.id,
                                        staticClass: "pr-1",
                                        staticStyle: { width: "fit-content" },
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-img",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                src: _vm.Certification.getCertificationImgUrlByName(
                                                                  cert.name
                                                                ),
                                                                width: "35px",
                                                              },
                                                            },
                                                            "v-img",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(cert.name)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column p-3 h-100 w-100",
                  attrs: { id: "profile" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "flex-grow-0", attrs: { "no-gutters": "" } },
                    [
                      _c("h5", { staticClass: "black--text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.mutableStemdoer.name +
                              " " +
                              _vm.mutableStemdoer.surname.split("")[0]
                          ) + "."
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "flex-grow-0", attrs: { "no-gutters": "" } },
                    [
                      _c("h4", { staticClass: "black--text" }, [
                        _vm._v(_vm._s(_vm.mutableStemdoer.position)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isLoaded
                    ? _c(
                        "v-row",
                        {
                          staticClass: "flex-grow-0",
                          attrs: { "no-gutters": "" },
                        },
                        _vm._l(
                          _vm.mutableStemdoer.badgesSkills.slice(0, 5),
                          function (badge, index) {
                            return _c(
                              "v-chip",
                              {
                                key: index,
                                staticClass: "mr-1 my-1 chips",
                                attrs: { density: "compact" },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(badge.technology) +
                                    "\n          "
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "flex-grow-0 mt-auto pb-2 justify-end",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c(
                          "div",
                          { staticClass: "mr-2" },
                          [
                            _c("Sbutton", {
                              staticStyle: {
                                "min-height": "33px !important",
                                height: "33px !important",
                                width: "105px !important",
                              },
                              attrs: {
                                sText: _vm.$t(
                                  "explore.stemdoer-card.btnDetail"
                                ),
                                action: () => {
                                  _vm.openStemdoerDetailModal(
                                    _vm.mutableStemdoer
                                  )
                                },
                                btnStyle: "secondary",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "stemdoerBtnAdd",
                              attrs: {
                                elevation: "0",
                                disabled:
                                  !_vm.mutableStemdoer.availability.available,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addToCart(_vm.mutableStemdoer)
                                },
                              },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  width: 22,
                                  src: "/content/svgs/person-add.svg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { defineComponent, inject, onMounted, ref } from 'vue';
import CandidatesList from '@/sections/client/candidates/candidates-list/CandidatesList.vue';
import CandidatesFilter from '@/sections/client/candidates/candidates-filter/CandidatesFilter.vue';
import StemdoerService from '@/sections/admin/entities/stemdoer/stemdoer.service';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import { useStore } from '@/plugins/vuex';
import NoContent from './no-content/noContent.vue';

export default defineComponent({
  components: {
    CandidatesList,
    CandidatesFilter,
    NoContent,
  },
  setup() {
    const store = useStore();
    const stemdoerService: StemdoerService = inject('stemdoerService');
    const candidates = ref<IStemdoer[]>([]);
    const isLoading = ref(true);
    const isCandidateListEmpty = ref(true);

    onMounted(() => {
      retrieveCandidates();
    });

    const retrieveCandidates = async () => {
      const loggedClientId = store.getters['client'].id;
      const currentDate = new Date();
      candidates.value = await stemdoerService.retrieveByClientTeams(loggedClientId, currentDate);
      isCandidateListEmpty.value = candidates.value.length == 0;
      isLoading.value = false;
    };

    return {
      candidates,
      isLoading,
      isCandidateListEmpty,
      retrieveCandidates,
    };
  },
});

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "login" } }, [
    _c("div", { staticClass: "login-container" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "login-form", attrs: { "no-validate": "" } }, [
        _c(
          "div",
          { staticClass: "form-text" },
          [
            _c("div", [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.messages.info.register.account")
                  ),
                },
              }),
              _vm._v(" "),
              _c("a", {
                staticClass: "alert-link text-body-1-bold",
                staticStyle: { color: "#4237ff" },
                attrs: { "data-cy": "registerOpen" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.messages.info.register.toLogin")
                  ),
                },
                on: { click: _vm.gotoLogin },
              }),
            ]),
            _vm._v(" "),
            _vm.keyMissing || _vm.error || _vm.success
              ? _c("SPopUp", {
                  attrs: { sText: _vm.getMessage, isValid: !!_vm.success },
                  on: { close: _vm.handleClose },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.keyMissing
          ? _c(
              "form",
              {
                staticClass: "SForm",
                attrs: { name: "form", role: "form" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("div", { staticClass: "form-title" }, [
                  _c("h1", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("reset.request.title")),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", {
                    staticClass: "login-span",
                    attrs: { for: "newPassword" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.form['newpassword.label']")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "input-form-group-stemdo",
                      attrs: { id: "inpunts" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.resetAccount.newPassword.$model,
                            expression: "$v.resetAccount.newPassword.$model",
                          },
                        ],
                        staticClass: "input-form-stemdo",
                        class: {
                          valid:
                            !_vm.$v.resetAccount.newPassword.$invalid &&
                            _vm.$v.resetAccount.newPassword.$dirty &&
                            _vm.$v.resetAccount.newPassword.$model,
                          invalid:
                            _vm.$v.resetAccount.newPassword.$invalid &&
                            _vm.$v.resetAccount.newPassword.$dirty &&
                            _vm.$v.resetAccount.newPassword.$model,
                          "input-error":
                            _vm.$v.resetAccount.newPassword.$invalid &&
                            _vm.$v.resetAccount.newPassword.$dirty &&
                            _vm.$v.resetAccount.newPassword.$model,
                        },
                        attrs: {
                          type: "password",
                          id: "newPassword",
                          name: "newPassword",
                          minlength: "8",
                          maxlength: "50",
                          required: "",
                          "data-cy": "resetPassword",
                        },
                        domProps: {
                          value: _vm.$v.resetAccount.newPassword.$model,
                        },
                        on: {
                          focus: function ($event) {
                            _vm.showValidationText = true
                          },
                          blur: function ($event) {
                            _vm.showValidationText = false
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$v.resetAccount.newPassword,
                              "$model",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showValidationText,
                              expression: "showValidationText",
                            },
                          ],
                          staticClass: "validation-text-container",
                        },
                        [
                          _c("span", {
                            staticClass: "validation-text",
                            class: {
                              "valid-password":
                                _vm.showValidationText &&
                                _vm.$v.resetAccount.newPassword.$model &&
                                _vm.$v.resetAccount.newPassword.minLength,
                              "invalid-password":
                                _vm.showValidationText &&
                                _vm.$v.resetAccount.newPassword.$model &&
                                !_vm.$v.resetAccount.newPassword.minLength,
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "global.messages.validate.passwordPattern.minlength"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "validation-text",
                            class: {
                              "valid-password":
                                _vm.showValidationText &&
                                _vm.$v.resetAccount.newPassword.$model &&
                                _vm.$v.resetAccount.newPassword.upperCase,
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "global.messages.validate.passwordPattern.uppercase"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "validation-text",
                            class: {
                              "valid-password":
                                _vm.showValidationText &&
                                _vm.$v.resetAccount.newPassword.$model &&
                                _vm.$v.resetAccount.newPassword.lowerCase,
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "global.messages.validate.passwordPattern.lowercase"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "validation-text",
                            class: {
                              "valid-password":
                                _vm.showValidationText &&
                                _vm.$v.resetAccount.newPassword.$model &&
                                _vm.$v.resetAccount.newPassword.number,
                            },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "global.messages.validate.passwordPattern.number"
                                )
                              ),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      (_vm.$v.resetAccount.newPassword.$anyDirty &&
                        _vm.$v.resetAccount.newPassword.$invalid) ||
                      (_vm.isFormInvalid &&
                        !_vm.$v.resetAccount.newPassword.$model &&
                        _vm.$v.resetAccount.newPassword.$invalid)
                        ? _c(
                            "div",
                            { staticStyle: { "margin-top": "0.45em" } },
                            [
                              !_vm.$v.resetAccount.newPassword.required
                                ? _c("span", {
                                    staticClass: "span-error",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "global.messages.validate.newpassword.required"
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      staticStyle: { "margin-top": "22px" },
                    },
                    [
                      _c("label", {
                        staticClass: "login-span",
                        attrs: { for: "confirmPassword" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("global.form['confirmpassword.label']")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.resetAccount.confirmPassword.$model,
                            expression:
                              "$v.resetAccount.confirmPassword.$model",
                          },
                        ],
                        staticClass: "input-form-stemdo",
                        class: {
                          valid: !_vm.$v.resetAccount.confirmPassword.$invalid,
                          invalid: _vm.$v.resetAccount.confirmPassword.$invalid,
                          "input-error":
                            _vm.$v.resetAccount.confirmPassword.$invalid &&
                            _vm.$v.resetAccount.confirmPassword.$dirty,
                        },
                        attrs: {
                          type: "password",
                          id: "confirmPassword",
                          name: "confirmPassword",
                          minlength: "4",
                          maxlength: "50",
                          required: "",
                          "data-cy": "confirmResetPassword",
                        },
                        domProps: {
                          value: _vm.$v.resetAccount.confirmPassword.$model,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$v.resetAccount.confirmPassword,
                              "$model",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.resetAccount.confirmPassword.$anyDirty &&
                      _vm.$v.resetAccount.confirmPassword.$invalid
                        ? _c(
                            "div",
                            { staticStyle: { "margin-top": "0.45em" } },
                            [
                              !_vm.$v.resetAccount.confirmPassword
                                .sameAsPassword
                                ? _c("span", {
                                    staticClass: "span-error",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "global.messages.error.dontmatch"
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("SButton", {
                  staticClass: "mt-8",
                  attrs: {
                    btnStyle: "primary",
                    sText: "password.form.button",
                    action: _vm.validateForm,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "login-image", attrs: { "no-validate": "" } },
      [
        _c("img", {
          attrs: {
            src: require("@content/images/stemdo-black-logo.png"),
            alt: "stemdo Logo",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
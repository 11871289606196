import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import SModalCentered from '@/components/stemdo-components/s-modal-centered/SModalCentered.vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import router from '@/router';

export default defineComponent({
  props: {
    teamRequest: {
      type: Object,
      required: true,
    },
    isTRModified: {
      type: Boolean,
      required: true,
    },
    refreshTeamRequest: {
      type: Function,
      required: true,
    },
  },
  components: {
    SButton,
    SModalCentered,
  },
  setup(props) {
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const isTRApprovedModalOpen = ref(false);
    const isTRCancelledModalOpen = ref(false);
    const isSendProposalModalOpen = ref(false);
    const customModalProps = { bodyPaddingY: 0, fullWidthFooter: true };

    const hourlyRate = computed(() => {
      return props.teamRequest.stemdoerRates?.reduce((acc, sr) => acc + sr.rate, 0);
    });

    const openTRApprovedModal = () => {
      isTRApprovedModalOpen.value = true;
    };

    const closeTRApprovedModal = () => {
      isTRApprovedModalOpen.value = false;
    };

    const openTRCancelledModal = () => {
      isTRCancelledModalOpen.value = true;
    };

    const closeTRCancelledModal = () => {
      isTRCancelledModalOpen.value = false;
    };

    const openSendProposalModal = () => {
      isSendProposalModalOpen.value = true;
    };

    const closeSendProposalModal = () => {
      isSendProposalModalOpen.value = false;
    };

    const approveRequest = () => {
      teamRequestService.changeStatus(props.teamRequest.id, RequestStatus.VALIDATED).then(() => {
        openTRApprovedModal();
        props.refreshTeamRequest();
      });
    };

    const cancelRequest = () => {
      teamRequestService.changeStatus(props.teamRequest.id, RequestStatus.CANCELLED_BY_MANAGER).then(() => {
        openTRCancelledModal();
        props.refreshTeamRequest();
      });
    };

    const sendProposal = () => {
      if (props.teamRequest.status === RequestStatus.PENDING) {
        teamRequestService.validateAndPartialUpdate(props.teamRequest).then(() => {
          openSendProposalModal();
          props.refreshTeamRequest();
        });
      } else if (props.teamRequest.status === RequestStatus.VALIDATED) {
        teamRequestService.partialUpdate(props.teamRequest).then(() => {
          openSendProposalModal();
          props.refreshTeamRequest();
        });
      }
    };

    return {
      SButton,
      SModalCentered,
      RequestStatus,
      customModalProps,
      isTRApprovedModalOpen,
      isTRCancelledModalOpen,
      isSendProposalModalOpen,
      hourlyRate,
      approveRequest,
      cancelRequest,
      sendProposal,
      closeTRApprovedModal,
      closeTRCancelledModal,
      closeSendProposalModal,
    };
  },
});
